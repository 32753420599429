declare const Stomp: any;

/**
 * Classe responsável por gerenciar a comunicação com o RabbitMQ.
 */
class RabbitMQAdapter {

    client: any = null;

    private _rabbitMqConfig: any = null;
    private _queuesLoggedUser: any = null;

    get rabbitMqConfig(): any {
        if (!this._rabbitMqConfig)
            this._rabbitMqConfig = JSON.parse(sessionStorage.getItem("rabbit_mq_config") || '{}');

        return this._rabbitMqConfig;
    }

    set rabbitMqConfig(rabbitMqConfig: any) {
        this._rabbitMqConfig = rabbitMqConfig;

        sessionStorage.setItem("rabbit_mq_config", JSON.stringify(rabbitMqConfig));
    }

    get queuesLoggedUser() : any {
        if (!this._queuesLoggedUser)
            this._queuesLoggedUser = JSON.parse(sessionStorage.getItem("queues_logged_user") || '{}') as any;

        return this._queuesLoggedUser;
    }

    set queuesLoggedUser(queuesLoggedUser) {
        this._queuesLoggedUser = queuesLoggedUser;

        sessionStorage.setItem("queues_logged_user", JSON.stringify(queuesLoggedUser));
    }

    async connect(): Promise<void> {
        return new Promise(async (resolve: Function, reject: Function) => {     

    if (!Array.isArray(this.queuesLoggedUser)) {
                const headers: Headers = new Headers();
                headers.append("X-Requested-With", "XMLHttpRequest");
                headers.append('Content-Type', 'application/json');
                headers.append('Accept', 'application/json');

                const result: Response = await fetch('/conta/getdatarabbitmq', {
                    method: 'GET',
                    headers: headers,
                    credentials: 'same-origin'
                });

                try {
                  const response: any = await result.json();

                  this.rabbitMqConfig = response.rabbit_mq_config;
                  this.queuesLoggedUser = response.queues_logged_user;
                } catch (error) {}
            }


            if(this.rabbitMqConfig && this.rabbitMqConfig.active){
                const schema: string = this.rabbitMqConfig.ssl ? 'wss' : 'ws';

                const webSocket: WebSocket = new WebSocket(`${schema}://${ this.rabbitMqConfig.host}:${ this.rabbitMqConfig.port_ws}/ws`);
                this.client = Stomp.over(webSocket);
                this.client.connect(this.rabbitMqConfig.user, this.rabbitMqConfig.pass, resolve, reject, this.rabbitMqConfig.vhost);
                this.client.debug = null;
            }
        });
    }
}