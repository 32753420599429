interface JQuery {
    progressbar: Function
}

/**
 * Classe responsável por gerenciar a comunicação com a fila do faturamento da agenda.
 */
document.addEventListener('DOMContentLoaded', () =>
    new class RabbitMQQueueAgenda {
        adapter: RabbitMQAdapter;
        faturamentoAgendaBgInfo: HTMLElement | null;
        agendaBgProgressbar: HTMLElement | null;
        agendaBgProgressbarPercent: HTMLElement | null;
        agendaBgTotalErrors: HTMLElement | null;

        /**
         * Método construtor da classe.
         */
        constructor() {
            this.faturamentoAgendaBgInfo = document.getElementById('faturamento_agenda_bg_info');
            this.agendaBgProgressbar = document.getElementById('faturamento_agenda_bg_progressbar');
            this.agendaBgProgressbarPercent = document.getElementById('faturamento_agenda_bg_progressbar_percent');
            this.agendaBgTotalErrors = document.getElementById('faturamento_agenda_bg_total_errors');

            this.adapter = new RabbitMQAdapter();

            this.subscribeQueue().catch(console.error.bind(console));
        }

        async subscribeQueue(): Promise<void> {
            await this.adapter.connect();

             if (this.adapter.rabbitMqConfig && this.adapter.rabbitMqConfig.active && this.adapter.queuesLoggedUser.includes('faturar_producao_agenda_ws')) {
                this.adapter.client.subscribe(`/exchange/faturar_producao_agenda_ws/client`, (data) => {
                    const body = JSON.parse(data.body);

                    if (!!this.faturamentoAgendaBgInfo) {
                        this.faturamentoAgendaBgInfo.classList.add('hide');
                    }
                    if (!!this.agendaBgProgressbar) {
                        this.agendaBgProgressbar.classList.remove('hide');
                    }
                    if (!!this.agendaBgProgressbarPercent) {
                        this.agendaBgProgressbarPercent.classList.remove('hide');
                    }


                    if (body.json.percent) {
                        // @ts-ignore
                        $(this.agendaBgProgressbar).progressbar({
                            value: parseInt(body.json.percent),
                            max: 100,
                            change: function () { },
                            complete: function () { }
                        });

                        if (!!this.agendaBgProgressbarPercent)
                            this.agendaBgProgressbarPercent.innerText = `Percentual: ${body.json.percent}%`;
                    }

                    if (body.json.error_total) {
                        if (!!this.agendaBgTotalErrors) {
                            this.agendaBgTotalErrors.classList.remove('hide');
                            this.agendaBgTotalErrors.innerText = `Total de erros: ${body.json.error_total}`
                        }

                        console.log(body.json.error_message);
                    }
                });
            }
        }
    }
);