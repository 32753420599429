var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
document.addEventListener('DOMContentLoaded', function () {
    new (function () {
        function RabbitMQQueueEsus() {
            this.adapter = new RabbitMQAdapter();
            this.exportacaoesusbg = document.getElementById('esus_bg');
            this.exportacaoesusBgInfo = document.getElementById('esus_bg_info');
            this.esusBgProgressbar = document.getElementById('esus_bg_progressbar');
            this.esusBgProgressbarPercent = document.getElementById('esus_bg_progressbar_percent');
            this.esusBgTotalErrors = document.getElementById('esus_bg_total_errors');
            this.mapSheetTypeTitle = new Map()
                .set('ORGAOS_CONSELHOS', 'Orgão/Conselhos')
                .set('PROFISSIONAIS', 'Profissionais')
                .set('UNIDADES_SAUDE', 'Unidades de Saúde')
                .set('EQUIPES', 'Equipes')
                .set('MICROAREAS', 'Microareas')
                .set('TIPO_LOGRADOURO', 'Tipos de Logradouro')
                .set('LOTACOES', 'Lotações')
                .set('CADASTRO_INDIVIDUAL', 'Cadastro Individual')
                .set('DOMICILIOS', 'Domicílios')
                .set('ATENDIMENTO_INDIVIDUAL', 'Atendimento Individual')
                .set('ATENDIMENTO_ODONTOLOGICO', 'Atendimento Odontológico')
                .set('FICHA_PROCEDIMENTOS', 'Ficha de Procedimentos')
                .set('ATIVIDADE_COLETIVA', 'Atividade Coletiva')
                .set('VISITA_DOMICILIAR', 'Visita Domiciliar')
                .set('AVALIACAO_ELEGIBILIDADE', 'Avaliação de Elegibilidade')
                .set('ATENDIMENTO_DOMICILIAR', 'Atendimento Domiciliar')
                .set('MARCADORES_CONSUMO_ALIMENTAR', 'Marcadores de Consumo Alimentar')
                .set('ZIKA_MICROCEFALIA', 'Zika/Microcefalia')
                .set('VACINA', 'Vacinação');
            this.subscribeQueue().catch(console.error.bind(console));
        }
        RabbitMQQueueEsus.prototype.subscribeQueue = function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, this.adapter.connect()];
                        case 1:
                            _a.sent();
                            if (this.adapter.rabbitMqConfig && this.adapter.rabbitMqConfig.active && this.adapter.queuesLoggedUser.includes('exportacao_esus_ws')) {
                                this.adapter.client.subscribe("/exchange/exportacao_esus_ws/client", function (data) {
                                    var body = JSON.parse(data.body);
                                    if (!!_this.exportacaoesusBgInfo)
                                        _this.exportacaoesusBgInfo.classList.add('hide');
                                    var divExportacao = _this.criaPopulaDivExportacao(body.exportId);
                                    if (!!divExportacao)
                                        body.exportDataSheets.forEach(function (item) { return _this.criaPopulaConteudoDivExportacao(divExportacao, item); });
                                });
                            }
                            return [2];
                    }
                });
            });
        };
        RabbitMQQueueEsus.prototype.criaPopulaDivExportacao = function (exportId) {
            if (!!!this.exportacaoesusbg)
                return null;
            var divExportacao = this.exportacaoesusbg.querySelector("#esus_exportacao_" + exportId);
            if (!divExportacao) {
                divExportacao = document.createElement('div');
                divExportacao.id = "esus_exportacao_" + exportId;
                divExportacao.classList.add('esus_exportacao');
                var title = document.createElement('span');
                title.classList.add('esus_exportacao_title');
                title.innerText = "Exporta\u00E7\u00E3o c\u00F3digo: " + exportId;
                divExportacao.appendChild(title);
                this.exportacaoesusbg.appendChild(divExportacao);
            }
            return divExportacao;
        };
        RabbitMQQueueEsus.prototype.criaPopulaConteudoDivExportacao = function (divExportacao, item) {
            var parent = divExportacao.querySelector("div[data-sheet_type=\"" + item.sheetType + "\"]");
            if (!parent) {
                parent = document.createElement('div');
                parent.classList.add('esus_exportacao_sheet', 'mt10');
                parent.dataset.sheet_type = item.sheetType;
                divExportacao.appendChild(parent);
            }
            var info = this.criaDivExportacaoInfo(parent);
            this.criaDivExportacaoSheetName(parent, item.sheetType);
            var sheetValues = this.criaDivExportacaoSheetValues(parent);
            info.title = "\nItens com sucesso: " + item.totalSuccess + "\nItens com erro: " + item.totalErrors + "\nItens inv\u00E1lidos: " + item.totalInvalids;
            if (item.totalErrors > 0 || item.totalInvalids > 0) {
                info.style.color = '#f33e3e';
            }
            else {
                info.style.color = 'black';
            }
            if (item.currentItem > 1) {
                sheetValues.innerText = "\nTotal de p\u00E1gina(s): " + item.total + "\nFaltam " + item.currentItem + " p\u00E1gina(s)";
            }
            else {
                sheetValues.innerText = "\nTotal de p\u00E1gina(s): " + item.total + "\nTodas as p\u00E1ginas processadas.\n";
            }
        };
        RabbitMQQueueEsus.prototype.criaDivExportacaoSheetValues = function (parent) {
            var sheetValues = parent.querySelector('.esus_exportacao_values');
            if (!sheetValues) {
                sheetValues = document.createElement('span');
                sheetValues.classList.add('esus_exportacao_values');
                parent.appendChild(sheetValues);
            }
            return sheetValues;
        };
        RabbitMQQueueEsus.prototype.criaDivExportacaoSheetName = function (parent, sheetType) {
            var sheetName = parent.querySelector('.esus_exportacao_sheet');
            if (!sheetName) {
                sheetName = document.createElement('span');
                sheetName.classList.add('esus_exportacao_sheet');
                sheetName.innerText = (this.mapSheetTypeTitle.get(sheetType) || '') + ": ";
                parent.appendChild(sheetName);
            }
        };
        RabbitMQQueueEsus.prototype.criaDivExportacaoInfo = function (parent) {
            var info = parent.querySelector('.esus_exportacao_info');
            if (!info) {
                info = document.createElement('span');
                info.classList.add('esus_exportacao_info', 'fa', 'fa-info-circle');
                parent.appendChild(info);
            }
            return info;
        };
        return RabbitMQQueueEsus;
    }());
});
