/**
 * Classe responsável por gerenciar a comunicação com a fila de envio RNDS
 */
document.addEventListener('DOMContentLoaded', () =>
    new class RabbitMQQueueEnvioRnds {
        adapter: RabbitMQAdapter;
        envioRndsBg: HTMLElement | null;
        envioRndsBgInfo: HTMLElement | null;

        /**
         * Método construtor da classe.
         */
        constructor() {
            this.envioRndsBg = document.getElementById('envio_rnds_bg');
            this.envioRndsBgInfo = document.getElementById('envio_rnds_bg_info');

            this.adapter = new RabbitMQAdapter();

            this.subscribeQueue().catch(console.error.bind(console));
        }

        async subscribeQueue(): Promise<void> {
            await this.adapter.connect();

             if (this.adapter.rabbitMqConfig && this.adapter.rabbitMqConfig.active && this.adapter.queuesLoggedUser.includes('token_rnds_ws')) {
                this.adapter.client.subscribe(`/exchange/token_rnds_ws/client`, (data) => {
                    const body = JSON.parse(data.body);

                    if (body.json) {
                        this.envioRndsBgInfo.classList.add('hide');

                        const icon = `<a class="${body.json.icon_status}" href="#" style="margin: -2px 5px;"></a>`;
                        const itemText = `<div>${icon} <a href="${body.json.link_ref}" title="${body.json.title}">${body.json.message}</a></div>`;

                        this.envioRndsBg.innerHTML += itemText;
                    }
                });
            }
        }
    }
);