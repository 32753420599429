interface JQuery {
    progressbar: Function
}

interface ExportDataSheet {
    sheetType: string;
    total: number;
    currentItem: number;
    totalSuccess: number;
    totalErrors: number;
    totalInvalids: number;
}

interface ExportData {
    exportId: string;
    exportDataSheets: ExportDataSheet[]
}

/**
 * Classe responsável por gerenciar a comunicação com a fila do faturamento da agenda.
 */
document.addEventListener('DOMContentLoaded', () => {
    new class RabbitMQQueueEsus {
        adapter: RabbitMQAdapter = new RabbitMQAdapter();
        exportacaoesusbg: HTMLElement | null = document.getElementById('esus_bg');
        exportacaoesusBgInfo: HTMLElement | null = document.getElementById('esus_bg_info');
        esusBgProgressbar: HTMLElement | null  = document.getElementById('esus_bg_progressbar');
        esusBgProgressbarPercent: HTMLElement | null = document.getElementById('esus_bg_progressbar_percent');
        esusBgTotalErrors: HTMLElement | null = document.getElementById('esus_bg_total_errors');

        mapSheetTypeTitle = new Map()
        .set('ORGAOS_CONSELHOS', 'Orgão/Conselhos')
        .set('PROFISSIONAIS', 'Profissionais')
        .set('UNIDADES_SAUDE', 'Unidades de Saúde')
        .set('EQUIPES', 'Equipes')
        .set('MICROAREAS', 'Microareas')
        .set('TIPO_LOGRADOURO', 'Tipos de Logradouro')
        .set('LOTACOES', 'Lotações')
        .set('CADASTRO_INDIVIDUAL', 'Cadastro Individual')
        .set('DOMICILIOS', 'Domicílios')
        .set('ATENDIMENTO_INDIVIDUAL', 'Atendimento Individual')
        .set('ATENDIMENTO_ODONTOLOGICO', 'Atendimento Odontológico')
        .set('FICHA_PROCEDIMENTOS', 'Ficha de Procedimentos')
        .set('ATIVIDADE_COLETIVA', 'Atividade Coletiva')
        .set('VISITA_DOMICILIAR', 'Visita Domiciliar')
        .set('AVALIACAO_ELEGIBILIDADE', 'Avaliação de Elegibilidade')
        .set('ATENDIMENTO_DOMICILIAR', 'Atendimento Domiciliar')
        .set('MARCADORES_CONSUMO_ALIMENTAR', 'Marcadores de Consumo Alimentar')
        .set('ZIKA_MICROCEFALIA', 'Zika/Microcefalia')
        .set('VACINA', 'Vacinação');

        /**
         * Método construtor da classe.
         */
        constructor() {
            this.subscribeQueue().catch(console.error.bind(console));
        }

        /**
         * Método responsável por inscrever na fila.
         */
        async subscribeQueue(): Promise<void> {
            await this.adapter.connect();

            if (this.adapter.rabbitMqConfig && this.adapter.rabbitMqConfig.active && this.adapter.queuesLoggedUser.includes('exportacao_esus_ws')) {
                this.adapter.client.subscribe(`/exchange/exportacao_esus_ws/client`, (data) => {
                    const body: ExportData = JSON.parse(data.body) as ExportData;

                    if (!!this.exportacaoesusBgInfo)
                        this.exportacaoesusBgInfo.classList.add('hide');

                    const divExportacao: HTMLElement | null = this.criaPopulaDivExportacao(body.exportId);

                    if (!!divExportacao)
                        body.exportDataSheets.forEach(item => this.criaPopulaConteudoDivExportacao(divExportacao, item));
                });
            }
        }

        /**
         * Método responsável por motar e popular a div de exibição dos dados da exportação.
         */
        criaPopulaDivExportacao(exportId: string): HTMLElement | null {
            if (!!!this.exportacaoesusbg)
                return null;

            let divExportacao: HTMLElement | null = this.exportacaoesusbg.querySelector(`#esus_exportacao_${exportId}`);

            if (!divExportacao) {
                divExportacao = document.createElement('div');
                divExportacao.id = `esus_exportacao_${exportId}`;
                divExportacao.classList.add('esus_exportacao');
                
                const title: HTMLElement = document.createElement('span');
                title.classList.add('esus_exportacao_title');
                title.innerText = `Exportação código: ${exportId}`;

                divExportacao.appendChild(title);

                this.exportacaoesusbg.appendChild(divExportacao);
            }

            return divExportacao;
        }

        /**
         * Método responsável por criar e popular o conteúdo da div de exibição dos dados da exportação.
         */
        criaPopulaConteudoDivExportacao(divExportacao: HTMLElement, item: ExportDataSheet) {
            let parent: HTMLElement | null = divExportacao.querySelector(`div[data-sheet_type="${item.sheetType}"]`);

            if (!parent) {
                parent = document.createElement('div');
                parent.classList.add('esus_exportacao_sheet', 'mt10');
                parent.dataset.sheet_type = item.sheetType;

                divExportacao.appendChild(parent);
            }

            const info: HTMLElement = this.criaDivExportacaoInfo(parent);

            this.criaDivExportacaoSheetName(parent, item.sheetType);

            const sheetValues: HTMLElement = this.criaDivExportacaoSheetValues(parent);

            info.title = `
Itens com sucesso: ${item.totalSuccess}
Itens com erro: ${item.totalErrors}
Itens inválidos: ${item.totalInvalids}`;

            if (item.totalErrors > 0 || item.totalInvalids > 0) {
                info.style.color = '#f33e3e';
            } else {
                info.style.color = 'black';
            }

            if (item.currentItem > 1) {
            sheetValues.innerText = `
Total de página(s): ${item.total}
Faltam ${item.currentItem} página(s)`;
            } else {
            sheetValues.innerText = `
Total de página(s): ${item.total}
Todas as páginas processadas.
`;    
            }
        }

        /**
         * Método responsável por criar o elemento de exibição dos valores da exportação.
         */
        criaDivExportacaoSheetValues(parent: HTMLElement): HTMLElement {
            let sheetValues: HTMLElement | null = parent.querySelector('.esus_exportacao_values');

            if (!sheetValues) {
                sheetValues = document.createElement('span');
                sheetValues.classList.add('esus_exportacao_values')
                
                parent.appendChild(sheetValues);
            }

            return sheetValues;
        }

        /**
         * Método responsável por criar o element de exibição do código da exportação.
         */
        criaDivExportacaoSheetName(parent: HTMLElement, sheetType: string): void {
            let sheetName: HTMLElement | null = parent.querySelector('.esus_exportacao_sheet');

            if (!sheetName) {
                sheetName = document.createElement('span');
                sheetName.classList.add('esus_exportacao_sheet');
                sheetName.innerText = `${this.mapSheetTypeTitle.get(sheetType) || ''}: `;
                
                parent.appendChild(sheetName);
            }
        }

        /**
         * Método responsável por criar o elemento de exibição das informações da exportação.
         */
        criaDivExportacaoInfo(parent: HTMLElement) {
            let info: HTMLElement | null = parent.querySelector('.esus_exportacao_info');

            if (!info) {
                info = document.createElement('span');
                info.classList.add('esus_exportacao_info', 'fa', 'fa-info-circle');
                
                parent.appendChild(info);
            }

            return info;
        }
    }
});